import ConfiguratorLayout from 'components/Configurator/Layout'
import ConfiguratorProducts from 'views/ConfiguratorProducts'
import { PageProps } from 'gatsby'
import React, { useEffect } from 'react'
import SEO from 'components/seo'
import { sessionStore } from 'utils/StorageUtils'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { trackStep } from 'utils/GtmTracking'

type DataProps = {}

const ConfiguratorProductsPage: React.FC<PageProps<DataProps>> = () => {
  useEffect(() => {
    const partnerId = sessionStore?.getItem('partnerId')
    if (partnerId) {
      trackStep(partnerId, 3)
      setTimeout(() => scrollTo('#gatsby-focus-wrapper'), 0)
    }
  }, [])

  return (
    <ConfiguratorLayout activePageId="products">
      <SEO
        title="Konfigurátor vypínačů Schneider Electric"
        description="Nakonfigurujte si vypínače a zásuvky přesně podle vašich představ. Široká nabídka stylových vypínačů a zásuvek pro jakýkoliv interiér."
      />
      <ConfiguratorProducts />
    </ConfiguratorLayout>
  )
}

export default ConfiguratorProductsPage
